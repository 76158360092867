import React from 'react';
import ShowData from './ShowData';
import WithLoading from '../WithLoading/WithLoading';
import ShowError from '../ShowError/ShowError'

const ShowDataWithLoading = WithLoading(ShowData);

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

class APIData extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    this.id = params.get("id");

    this.state = {
      "data": null,
      "loading": false,
      "error": null,
    };
  }

  fetchData() {
    if (! this.id || ! this.id.match(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/)) {
        this.setState({"error": "Invalid QR code"});
        this.setState({"loading": false});
        return;
    }

    const headers = { 'Authorization': 'Bearer ' + this.props.token };
    fetchWithTimeout('https://api.staging.thermovault.com/qr/info/STI%23' + this.id + '?time=' + Date.now(), { headers })
      .then(async response => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
            console.log(response)
            if (response.status === 401) {
              this.setState({"loading": false});

              // Todo: create a new session: the call below does cause a
              // new session to be created *but* it's missing a back-off
              // mechanism. For example: when the API always returns 401
              // then this code will cause a loop and will keep requesting
              // tokens from AWS which is undesirable.. It should have a
              // backoff mechanism..
              // this.props.setLoggedIn(false);
            }

            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        this.setState({"data": {...data, "fetched": new Date()}});
        this.setState({"error": null});
        this.setState({"loading": false});
      })
      .catch(error => {
        console.error('There was an error!', error);
        this.setState({"error": error.toString() });
        this.setState({"loading": false});
      });
  }

  updateData() {
    this.fetchData();
  };

  componentDidMount() {
    console.log("Component mounted: start timer");
    this.setState({"loading": true});
    this.fetchData();
    const obj = this;
    this.timer = setInterval(() => { obj.updateData() }, 30000);
  }

  componentWillUnmount() {
    // cancel timer
    clearInterval(this.timer);
  }

  render() {
    return (
      <>
        <ShowError error_msg={this.state.error} />
        <ShowDataWithLoading isLoading={this.state.loading} data={this.state.data} />
      </>
    );
  }
}

export default APIData;


