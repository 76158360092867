import { useTranslation } from 'react-i18next';

export default function WaterHeater({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <table border="1" cellSpacing="1" cellPadding="10">
        <tbody>
          <tr>
            <td>{t('api.last_connection')}</td>
            <td style={{backgroundColor: data.latest_update_status}}>{new Date(data.latest_update).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('api.relay.title')}</td>
            <td>{t('api.relay.' + data.relay_status)}</td>
          </tr>
          <tr>
            <td colSpan="2" align="center">{t('api.water_heater.temperature')}</td>
          </tr>
          <tr>
            <td>{t('api.now')}</td>
            <td>{data.temperature.now ? data.temperature.now + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_24h')}</td>
            <td>{data.temperature.max_24h ? data.temperature.max_24h + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_7d')}</td>
            <td>{data.temperature.max_7d ? data.temperature.max_7d + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td colSpan="2" align="center">{t('api.power_consumption')}</td>
          </tr>
          <tr>
            <td>{t('api.now')}</td>
            <td>{data.power_consumption.now !== undefined ? data.power_consumption.now + ' W' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_last_24h')}</td>
            <td>{data.power_consumption['24h'] !== undefined ? data.power_consumption['24h'] + ' kWh' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_last_7d')}</td>
            <td>{data.power_consumption['7d'] !== undefined ? data.power_consumption['7d'] + ' kWh' : t('api.not_available')}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <button>Test device/Close relay</button>
    </>
  );
}
